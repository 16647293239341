.notification {
  z-index: 90;
  min-height: 50px;
  width: 100%;
  color: #fff;
  margin-bottom: 0;
  padding: 10px 20px;
  font-family: Apercu;
  font-weight: bold;
  position: fixed;
  left: 0;
  box-shadow: 0 4px #d6d3d633;
}

.notification.ok {
  background-color: #3f8a93;
}

.notification.error {
  background-color: #c41d1d;
}

.notification.cs {
  color: #000;
  background-color: #a6d5d5;
}

.notification .notification-close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .notification-close, .notification-message {
    font-size: 14px;
  }
}

@media only screen and (min-width: 601px) {
  .notification-close, .notification-message {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .notification-close, .notification-message {
    font-size: 17px;
  }
}

@media only screen and (min-width: 992px) {
  .notification-close, .notification-message {
    font-size: 20px;
  }
}

/*# sourceMappingURL=index.css.map */
